import React from 'react';
import { VStack, Box, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Icon, Text, HStack, Badge } from '@chakra-ui/react';
import { FaEnvelope, FaExternalLinkAlt } from 'react-icons/fa';
import BlueBox from '../../../BlueBox';
import PropTypes from 'prop-types';

const EmailList = ({
  emailHistory,
  loadingArchivedActions,
  client,
  handleActionUpdate,
  handleViewEmail,
  totalEmails,
  isLoadingMoreEmails,
  fetchEmailHistory,
  emailPage,
  showArchivedCorrespondence
}) => {
  return (
    <>
      <Accordion allowMultiple>
        {emailHistory.map((item, index) => (
          <AccordionItem
            key={index}
            border="1px solid"
            borderColor={item.ActionItem?.some(action => action.aiCompletionStatus !== 'SUCCESS') ? "red.200" : "gray.200"}
            borderRadius="md"
            mb={4}
            position="relative"
            _hover={{
              borderColor: item.ActionItem?.some(action => action.aiCompletionStatus !== 'SUCCESS') ? "red.300" : "gray.300"
            }}
            transition="all 0.2s"
          >
            <AccordionButton>
              <Box flex="1">
                <HStack justify="space-between" w="100%">
                  <VStack align="start" spacing={1}>
                    <HStack>
                      <Icon
                        as={FaEnvelope}
                        color={item.ActionItem?.some(action => action.aiCompletionStatus !== 'SUCCESS') ? 'red.500' : 'green.500'}
                      />
                      <Text fontWeight="bold">
                        {item.subject || 'No Subject'}
                      </Text>
                    </HStack>
                    <Text fontSize="sm" color="gray.600">
                      {new Date(item.receivedAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </Text>
                  </VStack>
                  <HStack spacing={4}>
                    {item.ActionItem?.length > 0 && (
                      <Badge
                        colorScheme={item.ActionItem.some(action => action.aiCompletionStatus !== 'SUCCESS') ? "red" : "blue"}
                        variant={item.ActionItem.some(action => action.aiCompletionStatus !== 'SUCCESS') ? "solid" : "subtle"}
                      >
                        {item.ActionItem.filter(action => action.aiCompletionStatus === 'SUCCESS').length}
                        /{item.ActionItem.length} Actions
                      </Badge>
                    )}
                    <AccordionIcon />
                  </HStack>
                </HStack>
              </Box>
            </AccordionButton>

            <AccordionPanel pb={4}>
              <VStack align="stretch" spacing={4}>
                {item.metadata?.summary && (
                  <Box>
                    <Text fontWeight="semibold" mb={2}>Summary</Text>
                    <Text>{item.metadata.summary}</Text>
                  </Box>
                )}

                <Box>
                  <VStack align="stretch" spacing={2}>
                    <BlueBox
                      actions={item.ActionItem}
                      client={client}
                      id={item.id}
                      type="email"
                      transcriptId={item.id}
                      onArchive={() => {}}
                      onActionUpdate={handleActionUpdate}
                    />
                  </VStack>
                </Box>

                <HStack justify="flex-end">
                  <Button
                    onClick={() => handleViewEmail(item.id)}
                    rightIcon={<FaExternalLinkAlt />}
                    colorScheme="blue"
                    variant="outline"
                    size="sm"
                  >
                    View Full Email
                  </Button>
                </HStack>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      {emailHistory.length > 0 && totalEmails > emailHistory.length && (
        <Box textAlign="center" mt={4}>
          <Button
            onClick={() => fetchEmailHistory(emailPage + 1)}
            isLoading={isLoadingMoreEmails}
            loadingText="Loading more..."
            colorScheme="blue"
            variant="outline"
          >
            Load More
          </Button>
        </Box>
      )}
    </>
  );
};

EmailList.propTypes = {
  emailHistory: PropTypes.array.isRequired,
  loadingArchivedActions: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  handleActionUpdate: PropTypes.func.isRequired,
  handleViewEmail: PropTypes.func.isRequired,
  totalEmails: PropTypes.number.isRequired,
  isLoadingMoreEmails: PropTypes.bool.isRequired,
  fetchEmailHistory: PropTypes.func.isRequired,
  emailPage: PropTypes.number.isRequired,
  showArchivedCorrespondence: PropTypes.bool.isRequired
};

export default EmailList; 