import React from 'react';
import { Grid, GridItem, Box, Text } from '@chakra-ui/react';

const EmailComparison = ({ standardEmail, voicedEmail, useEmailVoice }) => {
  if (!useEmailVoice) return null;

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} w="100%" mt={4}>
      <GridItem>
        <Box p={4} borderWidth="1px" borderRadius="md">
          <Text fontWeight="bold" mb={2}>Standard Email</Text>
          <Text whiteSpace="pre-wrap">{standardEmail}</Text>
        </Box>
      </GridItem>
      <GridItem>
        <Box p={4} borderWidth="1px" borderRadius="md">
          <Text fontWeight="bold" mb={2}>Your Voice</Text>
          <Text whiteSpace="pre-wrap">{voicedEmail}</Text>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default EmailComparison;
