import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, VStack, Heading, Text, Textarea, Button, useToast, HStack, Table,
  Thead, Tbody, Tr, Th, Td, IconButton, Input, Alert, AlertIcon, Tooltip, Skeleton, SkeletonText, Container, Divider
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { createOutlookDraft } from '../utils/outlookUtils.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const styles = `
  .quill-wrapper {
    height: 500px;
    display: flex;
    flex-direction: column;
  }

  .quill-wrapper .quill-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .quill-wrapper .quill-container .ql-container {
    flex: 1;
    overflow-y: auto;
    font-size: 16px;
    font-family: inherit;
    max-width: 100%;
    height: auto;
  }

  .quill-wrapper .quill-container .ql-editor {
    height: 100%;
    min-height: 100%;
    padding: 1rem;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  .quill-wrapper .quill-container .ql-editor p,
  .quill-wrapper .quill-container .ql-editor ul,
  .quill-wrapper .quill-container .ql-editor ol {
    white-space: pre-wrap;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  .quill-wrapper .quill-container .ql-toolbar {
    background: white;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border: 1px solid #E2E8F0;
    border-bottom: none;
    max-width: 100%;
    flex-shrink: 0;
  }

  .quill-wrapper .quill-container .ql-container {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border: 1px solid #E2E8F0;
    border-top: none;
    flex: 1;
  }

  .preserve-whitespace {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const FollowUpPage = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const [followUpEmail, setFollowUpEmail] = useState({
    subject: '',
    summary: '',
    actionItemsClient: '',
    actionItemsAdvisor: ''
  });
  const [originalEmail, setOriginalEmail] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  const [discrepancies, setDiscrepancies] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [templateContent, setTemplateContent] = useState('');
  const quillRef = useRef(null);
  const [isOutlookConnected, setIsOutlookConnected] = useState(false);

  useEffect(() => {
    // Add styles to document head
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    // Cleanup
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user settings
        const settingsResponse = await axios.get(`${serverUrl}/api/user-settings`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setUserSettings(settingsResponse.data?.settings || {});

        // Fetch follow-up email
        const response = await axios.post(`${serverUrl}/api/follow-up/${type}/${id}`, 
          { useTemplate: settingsResponse.data?.settings?.useEmailTemplate }, 
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          }
        );

        if (settingsResponse.data?.settings?.useEmailTemplate) {
          const template = settingsResponse.data?.settings?.emailTemplate || '';
          const today = new Date().toLocaleDateString();
          const filledTemplate = template
            .replace(/{{clientName}}/g, (response.data?.clientInfo?.name || '[Client Name]'))
            .replace(/{{todaysDate}}/g, (today || '[Date]'))
            .replace(/{{body}}/g, response.data?.followUpEmail.emailBody || '[Email Body]')
            .replace(/{{advisorName}}/g, `${response.data?.user?.firstName} ${response.data?.user?.lastName}`)
            .replace(/{{signatureImage}}/g, response.data?.settings?.signatureImage || '');
          
          setTemplateContent(filledTemplate);
        } else {
          setTemplateContent(response.data?.followUpEmail.emailBody || '');
        }

        setFollowUpEmail(response.data?.followUpEmail);
        setOriginalEmail(response.data?.followUpEmail);
        setClientInfo(response.data?.clientInfo);
        setIsLoading(false);

        // Check for discrepancies
        const discrepancyResponse = await axios.post(`${serverUrl}/api/${type}/check-discrepancies`, {
          id: id,
          emailContent: response.data?.followUpEmail.emailBody
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setDiscrepancies(discrepancyResponse.data.discrepancies);

        // Add this new fetch call to check user connections
        const connectionsResponse = await axios.get(`${serverUrl}/api/user-connections`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        
        // Check if any connection is for Outlook
        const hasOutlookConnection = connectionsResponse.data.some(
          connection => connection.name === 'Microsoft'
        );
        setIsOutlookConnected(hasOutlookConnection);
      } catch (err) {
        setError('Failed to generate follow-up email');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [type, id, serverUrl]);

  const handleEdit = (field, value) => {
    setFollowUpEmail(prev => ({ ...prev, [field]: value }));
  };

  const handleReset = () => {
    setFollowUpEmail(originalEmail);
  };

  const handleTemplateChange = (content) => {
    setTemplateContent(content);
  };

  const renderEmailBodyInput = () => {
    return (
      <Box className="quill-container" width="100%">
        <ReactQuill
          ref={quillRef}
          value={templateContent}
          onChange={handleTemplateChange}
          modules={{
            toolbar: [
              [{ 'header': [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link'],
              ['clean']
            ],
            clipboard: {
              matchVisual: false
            },
            keyboard: {
              bindings: {
                tab: false,
                'indent-0': false
              }
            }
          }}
          formats={[
            'header',
            'bold', 'italic', 'underline', 'strike',
            'list', 'bullet',
            'link'
          ]}
          style={{ width: '100%' }}
        />
      </Box>
    );
  };

  const handleOpenInOutlook = async () => {
    try {
      const emailContent = userSettings?.useEmailTemplate ? 
        templateContent : 
        followUpEmail.emailBody;

      await axios.post(`${serverUrl}/api/follow-up/${type}/${id}/save`, {
        originalEmail,
        currentDraft: {
          ...followUpEmail,
          emailBody: emailContent
        }
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });

      const { messageData, webLink } = await createOutlookDraft(
        followUpEmail.subject,
        emailContent,
        true
      );

      window.open(webLink, '_blank');

      toast({
        title: 'Draft created and opened in Outlook',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating draft in Outlook:', error);
      toast({
        title: 'Failed to create draft in Outlook',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const LoadingSkeleton = () => (
    <Container maxW="container.xl" p={5}>
      <HStack justifyContent="space-between" mb={8}>
        <Skeleton height="40px" width="40px" />
        <Skeleton height="40px" width="400px" />
        <Skeleton height="40px" width="150px" />
      </HStack>
      
      <VStack spacing={8} align="stretch">
        <Box>
          <Skeleton height="30px" width="200px" mb={4} />
          <SkeletonText mt='4' noOfLines={3} spacing='4' skeletonHeight='4' />
        </Box>
        
        <Box>
          <Skeleton height="30px" width="150px" mb={4} />
          <Skeleton height="50px" />
        </Box>
        
        <Box>
          <Skeleton height="30px" width="150px" mb={4} />
          <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='4' />
        </Box>
      </VStack>
    </Container>
  );

  if (isLoading) return <LoadingSkeleton />;
  if (error) return (
    <Container maxW="container.xl" p={5}>
      <Alert status="error" variant="left-accent">
        <AlertIcon />
        <Text>{error}</Text>
      </Alert>
    </Container>
  );

  return (
    <Container maxW="container.xl" p={5}>
      {discrepancies && discrepancies !== "No discrepancies found." && (
        <Alert status="warning" mb={8} variant="left-accent" borderRadius="md">
          <AlertIcon />
          <Box>
            <Heading size="sm" mb={2}>Potential Discrepancies Detected</Heading>
            <Text>{discrepancies}</Text>
          </Box>
        </Alert>
      )}

      <HStack justifyContent="space-between" mb={8}>
        <IconButton
          icon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          aria-label="Go back"
          size="lg"
          variant="ghost"
        />
        <Heading size="lg">Follow Up Email to {clientInfo?.name}</Heading>
        <Tooltip 
          label={!isOutlookConnected ? "Please connect your Outlook account in Integrations" : "Create and view email draft in Outlook"}
          placement="top"
          hasArrow
        >
          <Button 
            colorScheme="blue" 
            onClick={handleOpenInOutlook}
            isDisabled={!isOutlookConnected}
            size="lg"
            leftIcon={<i className="fas fa-envelope" />}
          >
            Open in Outlook
          </Button>
        </Tooltip>
      </HStack>
      
      <VStack spacing={8} align="stretch">
        <Box 
          bg="white" 
          p={6} 
          borderRadius="lg" 
          boxShadow="sm"
          border="1px"
          borderColor="gray.100"
        >
          <Heading size="md" mb={4}>Client Information</Heading>
          <Divider mb={4} />
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Gross Income</Th>
                <Th>Net Worth</Th>
                <Th>Age</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{clientInfo?.grossIncome || 'N/A'}</Td>
                <Td>{clientInfo?.netWorth || 'N/A'}</Td>
                <Td>{clientInfo?.age || 'N/A'}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        <Box 
          bg="white" 
          p={6} 
          borderRadius="lg" 
          boxShadow="sm"
          border="1px"
          borderColor="gray.100"
        >
          <Heading size="md" mb={4}>Email Details</Heading>
          <Divider mb={4} />
          
          <VStack spacing={6} align="stretch">
            <Box>
              <Text mb={2} fontWeight="medium">Subject</Text>
              <Input
                value={followUpEmail.subject}
                onChange={(e) => handleEdit('subject', e.target.value)}
                size="lg"
                bg="gray.50"
              />
            </Box>

            <Box>
              <Text mb={2} fontWeight="medium">Email Body</Text>
              <Box 
                bg="gray.50" 
                borderRadius="md" 
                className="preserve-whitespace quill-wrapper"
                width="100%"
                height="500px"
                overflow="hidden"
              >
                {renderEmailBodyInput()}
              </Box>
            </Box>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default FollowUpPage;
