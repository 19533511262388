import React from 'react';
import { Grid, Card, CardBody, Stat, StatLabel, StatNumber, HStack, Icon, Text } from '@chakra-ui/react';
import { FaEnvelope, FaPhone, FaDollarSign } from 'react-icons/fa';

const ClientInfoCards = ({ client }) => {
  return (
    <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6}>
      <Card>
        <CardBody>
          <Stat>
            <StatLabel><HStack><Icon as={FaEnvelope} /> <Text>Email</Text></HStack></StatLabel>
            <StatNumber fontSize="md">{client?.email}</StatNumber>
          </Stat>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stat>
            <StatLabel><HStack><Icon as={FaPhone} /> <Text>Phone</Text></HStack></StatLabel>
            <StatNumber fontSize="md">{client?.phone}</StatNumber>
          </Stat>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stat>
            <StatLabel><HStack><Icon as={FaDollarSign} /> <Text>Net Worth</Text></HStack></StatLabel>
            <StatNumber fontSize="md">${client?.metadata?.netWorth?.toLocaleString() || 'N/A'}</StatNumber>
          </Stat>
        </CardBody>
      </Card>
    </Grid>
  );
};

export default ClientInfoCards; 