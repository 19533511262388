import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export const useCorrespondence = (id, serverUrl, client, toast) => {
  const [emailHistory, setEmailHistory] = useState([]);
  const [callHistory, setCallHistory] = useState([]);
  const [showArchivedCorrespondence, setShowArchivedCorrespondence] = useState(false);
  const [loadingArchivedActions, setLoadingArchivedActions] = useState({});
  const [isLoadingArchived, setIsLoadingArchived] = useState(false);
  const [totalEmails, setTotalEmails] = useState(0);
  const [totalCalls, setTotalCalls] = useState(0);
  const [emailPage, setEmailPage] = useState(1);
  const [callPage, setCallPage] = useState(1);
  const [isLoadingMoreEmails, setIsLoadingMoreEmails] = useState(false);
  const [isLoadingMoreCalls, setIsLoadingMoreCalls] = useState(false);
  const [pendingEmailCount, setPendingEmailCount] = useState(0);
  const [pendingCallCount, setPendingCallCount] = useState(0);
  const [correspondencePageSize] = useState(10);

  const getCorrespondenceDetails = async (correspondenceId, type, showArchived = false) => {
    try {
      const endpoint = type === 'email' ? 
        `${serverUrl}/api/emails/${correspondenceId}/action-items` :
        `${serverUrl}/api/calls/${correspondenceId}/action-items`;
      
      const response = await axios.get(`${endpoint}?showArchived=${showArchived}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching correspondence details:', error);
      return [];
    }
  };

  const handleArchiveToggle = async (isArchived) => {
    setIsLoadingArchived(true);
    setShowArchivedCorrespondence(isArchived);
    await fetchEmailHistory();
    await fetchCallHistory();
    setIsLoadingArchived(false);
  };

  const handleViewEmail = async (emailId) => {
    try {
      const response = await axios.get(`${serverUrl}/api/emails/${emailId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      const emailData = response.data;
      if (emailData && emailData.metadata?.webLink) {
        window.open(emailData.metadata.webLink, '_blank');
      } else {
        toast({
          title: "Error fetching email.",
          description: "No web link available for this email",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error fetching email:', error);
      toast({
        title: "Error fetching email.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleActionUpdate = ({ type, correspondenceId, correspondenceType, actionItem, actionItems }) => {
    const updateCorrespondence = (correspondence) => {
      const updatedActionItems = correspondence.ActionItem.map(item => {
        if (type === 'APPROVE_ALL') {
          const updatedItem = actionItems.find(updated => updated.id === item.id);
          return updatedItem || item;
        } else if (item.id === actionItem.id) {
          return type === 'ARCHIVE' ? null : actionItem;
        }
        return item;
      }).filter(Boolean);

      return {
        ...correspondence,
        ActionItem: updatedActionItems
      };
    };

    if (correspondenceType === 'email') {
      setEmailHistory(prev => prev.map(item => 
        item.id === correspondenceId ? updateCorrespondence(item) : item
      ));
    } else if (correspondenceType === 'call') {
      setCallHistory(prev => prev.map(item => 
        item.id === correspondenceId ? updateCorrespondence(item) : item
      ));
    }
  };

  const fetchEmailHistory = async (page = 1) => {
    try {
      setIsLoadingMoreEmails(true);
      const response = await axios.get(
        `${serverUrl}/api/clients/${id}/emails`,
        {
          params: {
            showArchived: showArchivedCorrespondence,
            page,
            pageSize: correspondencePageSize,
          },
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );
      
      const emailsWithActions = await Promise.all(
        response.data.items.map(async (item) => {
          const actionItems = await getCorrespondenceDetails(
            item.id, 
            'email', 
            false
          );
          return {
            ...item,
            ActionItem: actionItems
          };
        })
      );

      if (page === 1) {
        setEmailHistory(emailsWithActions);
      } else {
        setEmailHistory(prev => [...prev, ...emailsWithActions]);
      }
      
      setTotalEmails(response.data.total);
      setPendingEmailCount(response.data.pendingCount);
      setEmailPage(page);
    } catch (error) {
      console.error('Error fetching emails:', error);
      toast({
        title: "Error fetching emails",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingMoreEmails(false);
    }
  };

  const fetchCallHistory = async (page = 1) => {
    try {
      setIsLoadingMoreCalls(true);
      const response = await axios.get(
        `${serverUrl}/api/clients/${id}/calls`,
        {
          params: {
            showArchived: showArchivedCorrespondence,
            page,
            pageSize: correspondencePageSize,
          },
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );
      
      const callsWithActions = await Promise.all(
        response.data.items.map(async (item) => {
          const actionItems = await getCorrespondenceDetails(
            item.id, 
            'call', 
            false
          );
          return {
            ...item,
            ActionItem: actionItems
          };
        })
      );

      if (page === 1) {
        setCallHistory(callsWithActions);
      } else {
        setCallHistory(prev => [...prev, ...callsWithActions]);
      }
      
      setTotalCalls(response.data.total);
      setPendingCallCount(response.data.pendingCount);
      setCallPage(page);
    } catch (error) {
      console.error('Error fetching calls:', error);
      toast({
        title: "Error fetching calls",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingMoreCalls(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchEmailHistory();
      fetchCallHistory();
    }
  }, [id, showArchivedCorrespondence]);

  return {
    emailHistory,
    callHistory,
    showArchivedCorrespondence,
    loadingArchivedActions,
    handleArchiveToggle,
    handleActionUpdate,
    handleViewEmail,
    totalEmails,
    totalCalls,
    emailPage,
    callPage,
    isLoadingMoreEmails,
    isLoadingMoreCalls,
    pendingEmailCount,
    pendingCallCount,
    isLoadingArchived,
    fetchEmailHistory,
    fetchCallHistory
  };
}; 