import axios from 'axios';

const GRAPH_API_ENDPOINT = 'https://graph.microsoft.com/v1.0';
const serverUrl = process.env.REACT_APP_API_URL;

async function getAccessToken() {
    try {
        const response = await axios.get(`${serverUrl}/outlook/token`);
        console.log('Token response:', response.data);
        if (!response.data.accessToken) {
            throw new Error('Access token not found in response');
        }
        return response.data.accessToken;
    } catch (error) {
        console.error('Error getting access token:', error);
        throw error;
    }
}

function isTokenValid(token) {
    if (!token) return false;
    
    // Basic structure check (you might want to add more sophisticated checks)
    const parts = token.split('.');
    return parts.length === 3;
}

export const createOutlookDraft = async (subject, content, isHtml = true) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/outlook/draft`,
      {
        subject,
        body: content,
        contentType: isHtml ? 'html' : 'text'
      },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error creating Outlook draft:', error);
    throw error;
  }
};
