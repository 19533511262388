import React from 'react';
import { Grid, Card, CardBody, VStack, Heading, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const OverviewPanel = ({ client }) => {
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
      {/* Personal Information */}
      <Card>
        <CardBody>
          <VStack align="start" spacing={4}>
            <Heading size="md">Personal Information</Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} width="100%">
              <Stat>
                <StatLabel>Full Name</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.fullName || `${client?.firstName} ${client?.lastName}`}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Nickname</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.nickname || 'N/A'}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Date of Birth</StatLabel>
                <StatNumber fontSize="md">
                  {formatDate(client?.metadata?.dateOfBirth)}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Marital Status</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.maritalStatus || 'N/A'}</StatNumber>
              </Stat>
            </Grid>
          </VStack>
        </CardBody>
      </Card>

      {/* Professional Information */}
      <Card>
        <CardBody>
          <VStack align="start" spacing={4}>
            <Heading size="md">Professional Information</Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} width="100%">
              <Stat>
                <StatLabel>Employer</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.employer || 'N/A'}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Job Title</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.jobTitle || 'N/A'}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Company</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.companyName || 'N/A'}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Industry</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.industry || 'N/A'}</StatNumber>
              </Stat>
            </Grid>
          </VStack>
        </CardBody>
      </Card>

      {/* Client Relationship */}
      <Card>
        <CardBody>
          <VStack align="start" spacing={4}>
            <Heading size="md">Client Relationship</Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} width="100%">
              <Stat>
                <StatLabel>Client Since</StatLabel>
                <StatNumber fontSize="md">
                  {formatDate(client?.metadata?.clientSince)}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Status</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.clientStatus || 'N/A'}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Servicing Advisor</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.servicingAdvisor || 'N/A'}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Writing Advisor</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.writingAdvisor || 'N/A'}</StatNumber>
              </Stat>
            </Grid>
          </VStack>
        </CardBody>
      </Card>

      {/* Source Information */}
      <Card>
        <CardBody>
          <VStack align="start" spacing={4}>
            <Heading size="md">Source Information</Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} width="100%">
              <Stat>
                <StatLabel>CRM Source</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.source?.type || 'N/A'}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Source Details</StatLabel>
                <StatNumber fontSize="md">{client?.metadata?.source?.details || 'N/A'}</StatNumber>
              </Stat>
            </Grid>
          </VStack>
        </CardBody>
      </Card>
    </Grid>
  );
};

OverviewPanel.propTypes = {
  client: PropTypes.object.isRequired
};

export default OverviewPanel; 