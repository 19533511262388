import { useState, useEffect } from 'react';
import axios from 'axios';

export const useClientData = (id, serverUrl, toast) => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchClientData = async () => {
    try {
      const clientResponse = await axios.get(`${serverUrl}/api/clients/${id}`);
      setClient(clientResponse.data);
    } catch (error) {
      toast({
        title: "Error fetching client data",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientData();
  }, [id]);

  return { client, loading, fetchClientData };
}; 