import React from 'react';
import { HStack, Text, Switch, Heading } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import CallList from './CallList';

const CallPanel = ({ 
  callHistory, 
  showArchivedCorrespondence,
  isLoadingArchived,
  handleArchiveToggle,
  loadingArchivedActions,
  client,
  handleActionUpdate,
  totalCalls,
  isLoadingMoreCalls,
  fetchCallHistory,
  callPage,
  pendingCallCount
}) => {
  return (
    <>
      <HStack justify="space-between" mb={4}>
        <Heading size="md">Call History</Heading>
        <HStack>
          <Text>Show Archived</Text>
          <Switch
            isChecked={showArchivedCorrespondence}
            onChange={(e) => handleArchiveToggle(e.target.checked)}
            isDisabled={isLoadingArchived}
          />
        </HStack>
      </HStack>
      
      <CallList 
        callHistory={callHistory}
        loadingArchivedActions={loadingArchivedActions}
        client={client}
        handleActionUpdate={handleActionUpdate}
        totalCalls={totalCalls}
        isLoadingMoreCalls={isLoadingMoreCalls}
        fetchCallHistory={fetchCallHistory}
        callPage={callPage}
        pendingCallCount={pendingCallCount}
        showArchivedCorrespondence={showArchivedCorrespondence}
      />
    </>
  );
};

CallPanel.propTypes = {
  callHistory: PropTypes.array.isRequired,
  showArchivedCorrespondence: PropTypes.bool.isRequired,
  isLoadingArchived: PropTypes.bool.isRequired,
  handleArchiveToggle: PropTypes.func.isRequired,
  loadingArchivedActions: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  handleActionUpdate: PropTypes.func.isRequired,
  totalCalls: PropTypes.number.isRequired,
  isLoadingMoreCalls: PropTypes.bool.isRequired,
  fetchCallHistory: PropTypes.func.isRequired,
  callPage: PropTypes.number.isRequired,
  pendingCallCount: PropTypes.number.isRequired
};

export default CallPanel; 