import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const OrganizationUsersContext = createContext();

export const OrganizationUsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const token = Cookies.get('jwtToken');

    if (!token) {
      setLoading(false);
      return;
    }

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/users-in-organization`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching organization users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [serverUrl]);

  return (
    <OrganizationUsersContext.Provider value={{ users, loading, setUsers }}>
      {children}
    </OrganizationUsersContext.Provider>
  );
};

export const useOrganizationUsers = () => {
  const context = useContext(OrganizationUsersContext);
  if (context === undefined) {
    throw new Error('useOrganizationUsers must be used within an OrganizationUsersProvider');
  }
  return context;
}; 