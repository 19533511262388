import axios from 'axios';
import { SimpleGrid, Flex, FormControl, FormLabel, Input, Button, useToast } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';

const Login = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [disableButtons, setDisableButtons] = useState(false);
    const serverUrl = process.env.REACT_APP_API_URL;
    const onSubmit = async (e) => {
        e.preventDefault();
        const token = Cookies.get('jwtToken');
        console.log('Token:', token);
        try {
            const res = await axios.post(`${serverUrl}/login`, { email, password }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log(res.data);

            Cookies.set('jwtToken', res.data.token);
            // redirect to main app page
            window.location.href = '/';
        } catch (error) {
            if (error.response && error.response.data && error.response.data.info && error.response.data.info.message) {
                toast({
                    title: "Error",
                    description: error.response.data.info.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                setError('An error occurred. Please try again.');
            }
            console.error(error);
        }
    };

    const handleMicrosoftLogin = () => {
        window.location.href = `${serverUrl}/auth/microsoft`;
    };

    return (
        <SimpleGrid columns={2} spacing={0} width={"100%"} height={"100%"} data-simple className="ma-0 pa-0">
            <Flex justifyContent={"center"} alignItems={"center"}
                flexDirection={"column"} width={"100%"} height={"100%"}
                >
                   <div className="h-100" style={{
                    maxWidth: "450px"
                   }}>
                        <div style={{color: '#2B3674', fontSize: 36, fontWeight: '700', wordWrap: 'break-word'}}>Sign In</div>
                        <div style={{color: '#A3AED0', marginBottom: "4px", fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>Enter your email and password to sign in.</div>
                        <form onSubmit={onSubmit}>
                            <FormControl className="mt-8" width={"100%"} isRequired={true}>
                                <FormLabel>
                                    Email
                                </FormLabel>
                                <Input 
                                onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>
                            <FormControl
                            isRequired={true}
                            className="mt-4" width={"100%"} marginBottom={"4"}>
                                <FormLabel>
                                    Password
                                </FormLabel>
                                <Input type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                            <Button 
                                width={"100%"} 
                                className="mt-10" 
                                background={"#00417D"} 
                                color={"white"} 
                                borderRadius={"16px"} 
                                disabled={disableButtons}
                                type="submit"
                            >
                                Sign In
                            </Button>
                        </form>
                        <Button 
                            width={"100%"} 
                            className="mt-10" 
                            background={"#00417D"} 
                            color={"white"} 
                            borderRadius={"16px"} 
                            disabled={disableButtons}
                            onClick={handleMicrosoftLogin}
                            mt={2}
                        >
                            Sign In with Microsoft
                        </Button>
                        <Link to="/forgot-password">
                            <Button 
                                width={"100%"} 
                                className="mt-4" 
                                variant={"none"}
                            >
                                Forgot Password?
                            </Button>
                        </Link>
                        <Button 
                            width={"100%"} 
                            className="mt-4" 
                            variant={"none"}
                            onClick={() => navigate('/signup')}
                        >
                            Don&apos;t have an account? Sign up here
                        </Button>
                   </div>
            </Flex>
            <Flex>
                <Image src="/images/login_bg.jpeg" alt="LOGO JPEG" width={"100%"} height={"100%"}/>

            </Flex>
        </SimpleGrid>
    )
};

export default Login;