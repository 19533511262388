import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading, Switch, HStack, VStack, Text, Spinner, Select, Button } from '@chakra-ui/react';
import GrayBox from '../../GrayBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchEvents, EventBox, formatDate } from '../Meetings/Meetings';
import { motion, AnimatePresence } from 'framer-motion';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const formatMetadata = (data) => {
  const date = new Date(
    data?.metadata?.sentDateTime || data.receivedAt || data.startTime
  ).toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const clientNames = data?.clients
    .map((client) => `${client.firstName} ${client.lastName}`)
    .join(", ");

  return {
    title: data.title || data.metadata?.subject || data?.event?.subject || '',
    subtitle: date,
    clientNames,
    metadata: {
      ...data.metadata,
      date,
      clientNames,
    }
  };
};

const Home = ({ scrollRef }) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const [myFeed, setMyFeed] = useState([]);
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resyncLoading, setResyncLoading] = useState(true);
  const [sortOption, setSortOption] = useState('dateAsc');
  const [filterOption, setFilterOption] = useState(() => {
    return localStorage.getItem('filterOption') || 'emails and calls';
  });
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setLoading(true);
    axios.get(`${serverUrl}/api/my-feed`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        console.log('My Feed:', response.data);
        console.log('My Feed Emails:', response.data.filter(item => item.correspondenceType === 'email'));
        const sortedData = sortFeed([...response.data], sortOption);
        setMyFeed([...sortedData]);
        setFeed([...sortedData]);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching feed:', error);
        setLoading(false);
      });
  }, [serverUrl]);

  // Calculate total pages whenever feed or filters change
  useEffect(() => {
    const totalItems = filterFeed(feed).length;
    setTotalPages(Math.ceil(totalItems / itemsPerPage));
    setCurrentPage(1); // Reset to first page whenever feed changes
  }, [feed, filterOption]);

  const fetchJobs = async () => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleShowArchived = (showArchived) => {
    console.log('Show Archived Correspondences:', showArchived);
    setLoading(true);
    axios.get(`${serverUrl}/api/my-feed?showArchived=${showArchived}`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        console.log('Archived Correspondences:', response.data);
        const sortedData = response.data.sort((a, b) => {
          const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
          const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
          return dateB - dateA; // Descending order
        });
        setMyFeed([...sortedData]);
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleScrollPosition = () => {
      const savedPosition = sessionStorage.getItem('scrollPosition');
      if (savedPosition && scrollRef.current) {
        scrollRef.current.scrollTo(0, parseInt(savedPosition, 10));
        sessionStorage.removeItem('scrollPosition'); 
      }
    };

    if (!loading) {
      handleScrollPosition();
    }
  }, [loading, scrollRef, filterOption]);

  const handleArchive = (itemId) => {
    console.log('Archive', itemId);
    // Update the state to remove the archived item
    let newFeed = myFeed.filter(item => item.id !== itemId);
    setMyFeed([...newFeed]);
  };

  // Function to split summary into sentences using new line characters
  const getListItems = (summary) => {
    if (!summary) return [];

    return summary
      .split('\n')
      .map(line => line.trim())
      .filter(line => line !== '');
  };

  const sortFeed = (feedToSort, option = sortOption) => {
    return feedToSort.sort((a, b) => {
      const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
      const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
      if (option === 'dateDesc') {
        return dateA - dateB;
      } else if (option === 'dateAsc') {
        return dateB - dateA;
      }
      // Add more sorting options if needed
      return 0;
    });
  };

  const filterFeed = (feed) => {
    if (filterOption === 'emails and calls') return feed;
    if (filterOption === 'today') return [];
    return feed.filter(item => item.correspondenceType === filterOption);
  };

  const handleSortChange = (e) => {
    const selectedOption = e.target.value;
    setSortOption(selectedOption);

    const sortedMyFeed = sortFeed([...myFeed], selectedOption);
    setMyFeed(sortedMyFeed);

    const sortedEvents = sortFeed([...events], selectedOption);
    setEvents(sortedEvents);
  };

  const handleFilterChange = (e) => {
    const selectedOption = e.target.value;
    setFilterOption(selectedOption);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const refreshFeed = useCallback(() => {
    setLoading(true);
    axios.get(`${serverUrl}/api/my-feed`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
    .then(response => {
      console.log('Refreshed My Feed:', response.data);
      const sortedData = sortFeed(response.data);
      setMyFeed(sortedData);
      setFeed(sortedData);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error refreshing feed:', error);
      setLoading(false);
    });
  }, [serverUrl, sortOption]);

  // Apply filtering when filterOption changes
  useEffect(() => {
    const filteredFeed = filterFeed(feed);
    setMyFeed(filteredFeed);
  }, [filterOption]);

  // Updated filteredEvents to show events on the same calendar day
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.startTime);
    const today = new Date();
    return (
      eventDate.getFullYear() === today.getFullYear() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getDate() === today.getDate()
    );
  });

  // Persist filterOption to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('filterOption', filterOption);

    if (filterOption === 'today') {
      fetchEvents(setEvents);
    }
  }, [filterOption]);

  // Get current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFeedItems = filterFeed(myFeed).slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Box bg="white" p={8} overflowY="auto">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Heading color={'#00417D'} pb={4}>My Feed</Heading>

        <HStack justify="space-between" mb={4}>
          <HStack>
            <Select 
              value={sortOption} 
              onChange={handleSortChange} 
              w={200}
              disabled={loading}
            >
              <option value="dateAsc">Newest First</option>
              <option value="dateDesc">Oldest First</option>
            </Select>
            <Select 
              value={filterOption} 
              onChange={handleFilterChange} 
              w={200}
              disabled={loading}
            >
              <option value="today">Today's View</option>
              <option value="emails and calls">Emails and Calls</option>
              <option value="email">Emails</option>
              <option value="call">Calls</option>
            </Select>
          </HStack>
          <HStack>
            <Text>View Archived Correspondences</Text>
            <Switch
              isDisabled={loading}
              onChange={(e) => {
                const showArchived = e.target.checked;
                handleShowArchived(showArchived);
              }}
            />
          </HStack>
        </HStack>

        {loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {[1, 2, 3].map((index) => (
              <Box key={index} mb={4} p={6} borderWidth="1px" borderRadius="lg">
                <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='4' width="60%" mb={4} />
                <SkeletonText noOfLines={3} spacing='4' skeletonHeight='2' />
                <HStack mt={4} spacing={4}>
                  <Skeleton height="20px" width="100px" />
                  <Skeleton height="20px" width="80px" />
                </HStack>
              </Box>
            ))}
          </motion.div>
        ) : filterOption !== 'today' ? (
          <AnimatePresence mode="wait">
            <motion.div
              key="feed"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {currentFeedItems.length > 0 ? (
                currentFeedItems.map((data, index) => (
                  <motion.div
                    key={data.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <Box pr={20} mb={4}>
                      <GrayBox
                        {...formatMetadata(data)}
                        listItems={
                          data?.correspondenceType === 'email'
                            ? getListItems(data?.metadata?.summary) || []
                            : data?.correspondenceType === 'call'
                            ? getListItems(data?.summary) || []
                            : []
                        }
                        rows={data?.ActionItem}
                        transcriptId={data?.id}
                        client={data?.clients}
                        type={data?.correspondenceType}
                        id={data?.id}
                        onArchive={() => handleArchive(data.id)}
                        scrollRef={scrollRef}
                        errorItems={data?.ActionItem.filter(data => data.aiCompletionStatus === 'ERROR')}
                        correspondence={data}
                        nonClientSpeakers={data?.nonClientSpeakers}
                        resyncLoading={resyncLoading}
                        fetchJobs={fetchJobs}
                        refreshFeed={refreshFeed}
                      />
                    </Box>
                  </motion.div>
                ))
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <Box 
                    textAlign="center" 
                    py={8} 
                    px={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    borderStyle="dashed"
                    borderColor="gray.300"
                  >
                    <VStack spacing={4}>
                      <Text color="gray.600" fontSize="lg">
                        No items found in your feed yet. To get started:
                      </Text>
                      <VStack spacing={2} align="start">
                        <Text color="gray.500">
                          1. Go to the Integrations tab to connect your accounts
                        </Text>
                        <Text color="gray.500">
                          2. Connect to your organization's CRM if not already connected
                        </Text>
                        <Text color="gray.500">
                          3. Connect your Outlook account
                        </Text>
                      </VStack>
                      <Text color="gray.500">
                        Once connected, DataDasher will automatically process and display your new emails and calls here.
                      </Text>
                      <Button
                        as={RouterLink}
                        to="/integrations"
                        colorScheme="blue"
                        size="md"
                        mt={2}
                      >
                        Go to Integrations
                      </Button>
                    </VStack>
                  </Box>
                </motion.div>
              )}
              {/* Pagination Controls */}
              {totalPages > 1 && (
                <HStack justify="center" mt={4} spacing={4}>
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    isDisabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <Text>
                    Page {currentPage} of {totalPages}
                  </Text>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    isDisabled={currentPage === totalPages}
                  >
                    Next
                  </Button>
                </HStack>
              )}
            </motion.div>
          </AnimatePresence>
        ) : (
          // Existing code for 'today' view
          <AnimatePresence mode="wait">
            <motion.div
              key="today"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Text color={'gray'} fontSize={'16px'} mt={4}>
                Events from your Outlook Calendar for today will be displayed here.
              </Text>
              
              {eventsLoading ? (
                <Box mt={4}>
                  {[1, 2].map((index) => (
                    <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="lg">
                      <SkeletonText noOfLines={2} spacing='4' skeletonHeight='2' />
                      <HStack mt={4} spacing={4}>
                        <Skeleton height="20px" width="120px" />
                        <Skeleton height="20px" width="100px" />
                      </HStack>
                    </Box>
                  ))}
                </Box>
              ) : filteredEvents.length > 0 ? (
                <VStack spacing={4} mt={4} align="stretch">
                  {filteredEvents.map((event, index) => (
                    <motion.div
                      key={event.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                    >
                      <EventBox
                        eventId={event.id}
                        subject={event.subject}
                        startTime={formatDate(event.startTime, userTimeZone)}
                        endTime={formatDate(event.endTime, userTimeZone)}
                        webConfLink={event.webConfLink}
                        location={event.location}
                        audioBotJoinStatus={event.audioBotJoinStatus ?? true}
                        botId={event.botId ?? null}
                      />
                    </motion.div>
                  ))}
                </VStack>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <Box textAlign="center" py={8}>
                    <Text color="gray.500">No events for today.</Text>
                  </Box>
                </motion.div>
              )}
            </motion.div>
          </AnimatePresence>
        )}
      </motion.div>
    </Box>
  );
};

Home.propTypes = {
  scrollRef: PropTypes.shape({
    current: PropTypes.shape({
      scrollTo: PropTypes.func
    })
  })
};

export default Home;
