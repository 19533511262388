import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  VStack,
  HStack,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Skeleton
} from '@chakra-ui/react';

import ClientHeader from './components/ClientHeader';
import ClientInfoCards from './components/ClientInfoCards';
import EmailPanel from './components/EmailPanel';
import CallPanel from './components/CallPanel';
import ChatPanel from './components/ChatPanel';
import OverviewPanel from './components/OverviewPanel';

import { useClientData } from './hooks/useClientData';
import { useCorrespondence } from './hooks/useCorrespondence';
import { useChat } from './hooks/useChat';

const sfUrl = 'https://amazing-einstein-73385-dev-ed.develop.lightning.force.com/lightning/r/';
const wbUrl = 'https://app.crmworkspace.com/contacts/';
const rtUrl = 'https://crm.redtailtechnology.com/contacts/';

const ClientPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;

  const { 
    client, 
    loading,
    fetchClientData 
  } = useClientData(id, serverUrl, toast);

  const {
    emailHistory,
    callHistory,
    showArchivedCorrespondence,
    loadingArchivedActions,
    handleArchiveToggle,
    handleActionUpdate,
    handleViewEmail,
    totalEmails,
    totalCalls,
    emailPage,
    callPage,
    isLoadingMoreEmails,
    isLoadingMoreCalls,
    pendingEmailCount,
    pendingCallCount,
    isLoadingArchived,
    fetchEmailHistory,
    fetchCallHistory
  } = useCorrespondence(id, serverUrl, client, toast);

  const {
    chatHistory,
    inputValue,
    loadingChat,
    handleAskQuestion,
    setInputValue
  } = useChat(id, serverUrl, toast);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const openInCrm = (id, crmSource) => {
    if (crmSource === 'Salesforce') {
      window.open(`${sfUrl}${id}/view`, '_blank');
    }
    if (crmSource === 'Wealthbox') {
      window.open(`${wbUrl}${id}`, '_blank');
    }
    if (crmSource === 'RedTail') {
      window.open(`${rtUrl}${id}/`, '_blank');
    }
  };

  return (
    <Box p={8} bg="white">
      {loading ? (
        <VStack spacing={4} align="stretch">
          <Skeleton height="40px" />
          <Skeleton height="200px" />
          <Skeleton height="400px" />
        </VStack>
      ) : (
        <VStack spacing={8} align="stretch">
          <HStack justify="space-between" mb={4}>
            <Button onClick={() => navigate('/clients')} colorScheme="blue" variant="outline" size="sm">
              Back
            </Button>
          </HStack>

          <ClientHeader client={client} onOpenInCrm={openInCrm} />
          <ClientInfoCards client={client} />

          <Tabs colorScheme="blue">
            <TabList>
              <Tab>Overview</Tab>
              <Tab position="relative">
                Emails
                {!showArchivedCorrespondence && pendingEmailCount > 0 && (
                  <Box
                    position="absolute"
                    top="-8px"
                    right="-8px"
                    bg="red.500"
                    color="white"
                    borderRadius="full"
                    minW="20px"
                    height="20px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="xs"
                    fontWeight="bold"
                    px={1}
                  >
                    {pendingEmailCount}
                  </Box>
                )}
              </Tab>
              <Tab position="relative">
                Calls
                {!showArchivedCorrespondence && pendingCallCount > 0 && (
                  <Box
                    position="absolute"
                    top="-8px"
                    right="-8px"
                    bg="red.500"
                    color="white"
                    borderRadius="full"
                    minW="20px"
                    height="20px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="xs"
                    fontWeight="bold"
                    px={1}
                  >
                    {pendingCallCount}
                  </Box>
                )}
              </Tab>
              <Tab>Chat</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <OverviewPanel client={client} />
              </TabPanel>
              
              <TabPanel>
                <EmailPanel 
                  emailHistory={emailHistory}
                  showArchivedCorrespondence={showArchivedCorrespondence}
                  isLoadingArchived={isLoadingArchived}
                  handleArchiveToggle={handleArchiveToggle}
                  loadingArchivedActions={loadingArchivedActions}
                  client={client}
                  handleActionUpdate={handleActionUpdate}
                  handleViewEmail={handleViewEmail}
                  totalEmails={totalEmails}
                  isLoadingMoreEmails={isLoadingMoreEmails}
                  fetchEmailHistory={fetchEmailHistory}
                  emailPage={emailPage}
                  pendingEmailCount={pendingEmailCount}
                />
              </TabPanel>

              <TabPanel>
                <CallPanel 
                  callHistory={callHistory}
                  showArchivedCorrespondence={showArchivedCorrespondence}
                  isLoadingArchived={isLoadingArchived}
                  handleArchiveToggle={handleArchiveToggle}
                  loadingArchivedActions={loadingArchivedActions}
                  client={client}
                  handleActionUpdate={handleActionUpdate}
                  totalCalls={totalCalls}
                  isLoadingMoreCalls={isLoadingMoreCalls}
                  fetchCallHistory={fetchCallHistory}
                  callPage={callPage}
                  pendingCallCount={pendingCallCount}
                />
              </TabPanel>

              <TabPanel>
                <ChatPanel 
                  chatHistory={chatHistory}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  handleAskQuestion={handleAskQuestion}
                  loadingChat={loadingChat}
                  handleViewEmail={handleViewEmail}
                  formatDate={formatDate}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      )}
    </Box>
  );
};

export default ClientPage; 