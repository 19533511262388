import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  Button,
  Image,
  Grid,
  Tooltip,
  useToast,
  useBreakpointValue,
  HStack
} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { InfoIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { motion, AnimatePresence } from 'framer-motion';
import { Skeleton, SkeletonText } from '@chakra-ui/react';

const Integrations = () => {
  const [isSalesforceConnected, setIsSalesforceConnected] = useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [isRedtailConnected, setIsRedtailConnected] = useState(false);
  const [isZoomConnected, setIsZoomConnected] = useState(false);
  const [isMicrosoftConnected, setIsMicrosoftConnected] = useState(false);
  const [isWealthboxConnected, setIsWealthboxConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getConnections();

    // Check for toast message in query parameters
    const params = new URLSearchParams(location.search);
    const toastMessage = params.get('toast');
    if (toastMessage) {
      toast({
        title: "Notification",
        description: toastMessage,
        status: "error",
        duration: null, // Make the toast last forever
        isClosable: true,
      });
    }
  }, [location.search]); // Add location.search as a dependency

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const toastMessage = params.get('toast');
    if (toastMessage) {
      toast({
        title: "Notification",
        description: toastMessage,
        status: "error",
        duration: null, // Make the toast last forever
        isClosable: true,
      });
    }
  }, [location.search]); // Add location.search as a dependency

  function getConnections() {
    setIsLoading(true);
    const token = Cookies.get('jwtToken');

    Promise.all([
      axios.get(`${serverUrl}/api/organization-connections`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      }),
      axios.get(`${serverUrl}/api/user-connections`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      })
    ])
    .then(([orgRes, userRes]) => {
      setIsSalesforceConnected(orgRes.data.some(crm => crm.name.toLowerCase() === 'salesforce'));
      setIsRedtailConnected(orgRes.data.some(crm => crm.name.toLowerCase() === 'redtail'));
      setIsWealthboxConnected(orgRes.data.some(crm => crm.name.toLowerCase() === 'wealthbox'));
      
      const zoomConnected = userRes.data.some(connection => connection.name.toLowerCase() === 'zoom');
      setIsZoomConnected(zoomConnected);
      const microsoftConnected = userRes.data.some(connection => connection.name.toLowerCase() === 'microsoft');
      setIsMicrosoftConnected(microsoftConnected);
    })
    .catch(err => {
      console.error("Error fetching connections", err);
      setIsSalesforceConnected(false);
      setIsRedtailConnected(false);
      setIsWealthboxConnected(false);
      setIsZoomConnected(false);
      setIsMicrosoftConnected(false);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  function connectToSalesforce() {
    const token = Cookies.get('jwtToken');
    window.location.href = `${serverUrl}/salesforce/auth?token=${token}`;
  }

  function connectToRedTail() {
    navigate('/redtail/connect');
  }

  function connectToWealthbox() {
    const token = Cookies.get('jwtToken');
    window.location.href = `${serverUrl}/wealthbox/auth?token=${token}`;
  }

  function connectToOutlook() {
    window.location.href = `${serverUrl}/outlook/connect`;
  }

  function connectToZoom() {
    const token = Cookies.get('jwtToken');
  
    axios.delete(`${serverUrl}/api/zoom-oauth-credentials`, {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true
    })
    .then(response => {
      console.log('Zoom OAuth App Credentials deleted successfully:', response.data);
      // Navigate to the new URL only after successful deletion
      window.location.href = `${serverUrl}/auth/zoom?token=${token}`;
    })
    .catch(error => {
      console.error('Error deleting Zoom OAuth App Credentials:', error.response ? error.response.data : error.message);
      // Optionally, handle the error in the UI
    });
  }
  
  // Determine the number of columns based on screen size
  const orgGridColumns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });
  const userGridColumns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });

  const isAnyCrmConnected = (redtail, wealthbox, salesforce) => {
    return redtail || wealthbox || salesforce;
  };

  return (
    <Box bg="white" p={8}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Heading color={'#00417D'} fontSize={'32px'}>Integrations</Heading>
        
        {/* Organization Level Integrations */}
        <Box display="flex" alignItems="center">
          <Heading color={'#00417D'} fontSize={'24px'} mt={8}>Organization Level Integrations</Heading>
          <Tooltip 
            label="Connecting these integrations will grant DataDasher access for all users within your DataDasher organization, provided they have registered a DataDasher account. For example, if your email is john@financialadvisory.com and you are part of the Financial Advisory organization in DataDasher, then jane@financialadvisory.com, also in the same organization, will have access to the integration and be able to view clients within the integrated CRM."
            maxWidth="500px"
          >          
            <span style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '15px', verticalAlign: 'middle' }}>
              <FaInfoCircle />
            </span>
          </Tooltip>
        </Box>

        <AnimatePresence mode="wait">
          <motion.div
            key={isLoading ? 'loading' : 'loaded'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Grid templateColumns={`repeat(${orgGridColumns}, 1fr)`} gap={6} mt={4}>
              {isLoading ? (
                // Loading skeletons for CRM integrations
                [...Array(3)].map((_, index) => (
                  <Box
                    key={index}
                    bg="#F5F5F5"
                    p={8}
                    minHeight="350px"
                    borderRadius="8px"
                    border="1px"
                    borderColor="gray.200"
                  >
                    <VStack spacing={4} align="center">
                      <Skeleton height="100px" width="100px" />
                      <SkeletonText mt={4} noOfLines={1} spacing='4' skeletonHeight='4' width="60%" />
                      <SkeletonText noOfLines={2} spacing='4' skeletonHeight='2' width="80%" />
                      <Skeleton height="50px" width="150px" mt={4} />
                    </VStack>
                  </Box>
                ))
              ) : (
                // Actual CRM integration boxes
                <>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <ActionBox 
                      image={<Image src="/images/Redtail.png" alt="Redtail" boxSize="100px" objectFit="contain"/>}
                      description="Redtail"
                      description2={<Text mt={1}>Automatically update CRM fields and tasks in Redtail from your email and calls.</Text>}
                      buttonText={isRedtailConnected ? "Connected" : "Connect"}
                      buttonAction={connectToRedTail}
                      isDisabled={isAnyCrmConnected(isRedtailConnected, isWealthboxConnected, isSalesforceConnected)}
                      disabledTooltip={isRedtailConnected ? 
                        "You are currently connected to Redtail. Please contact support@datadasher.com if you'd like to switch to a different CRM." :
                        "You can only connect to one CRM at a time. Please contact support@datadasher.com to disconnect your current CRM before connecting to Redtail."}
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <ActionBox 
                      image={<Image src="/images/Wealthbox.png" alt="Wealthbox" boxSize="100px" objectFit="contain"/>}
                      description="Wealthbox"
                      description2={<Text mt={1}>Automatically update CRM fields and tasks in Wealthbox from your email and calls.</Text>}
                      buttonText={isWealthboxConnected ? "Connected" : "Connect"}
                      buttonAction={connectToWealthbox}
                      // isDisabled={isAnyCrmConnected(isRedtailConnected, isWealthboxConnected, isSalesforceConnected)}
                      disabledTooltip={isWealthboxConnected ? 
                        "You are currently connected to Wealthbox. Please contact support@datadasher.com if you'd like to switch to a different CRM." :
                        "You can only connect to one CRM at a time. Please contact support@datadasher.com to disconnect your current CRM before connecting to Wealthbox."}
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.3 }}
                  >
                    <ActionBox 
                      image={<Image src="/images/Salesforce.png" alt="Salesforce" boxSize="100px" objectFit="contain"/>}
                      description="Salesforce"
                      description2={
                        <VStack spacing={2}>
                          <Text mt={1}>Automatically update CRM fields and tasks in Salesforce from your email and calls.</Text>
                          <HStack color="orange.500">
                            <InfoIcon />
                            <Text fontSize="sm">Coming Soon</Text>
                          </HStack>
                        </VStack>
                      }
                      buttonText="Coming Soon"
                      buttonAction={connectToSalesforce}
                      isDisabled={true}
                      disabledTooltip="Salesforce integration is coming soon!"
                    />
                  </motion.div>
                </>
              )}
            </Grid>
          </motion.div>
        </AnimatePresence>

        {/* User Level Integrations */}
        <Box display="flex" alignItems="center">
          <Heading color={'#00417D'} fontSize={'24px'} mt={8}>User Level Integrations</Heading>
          <Tooltip 
            label="User Level Integrations allow individual users to connect their accounts to DataDasher. This means that each user can access and manage their own integrations independently, ensuring that their data remains private and secure. For example, if you connect your individual email or calendar, only you will have access to that information within DataDasher."
            maxWidth="500px"
          >          
            <span style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '15px', verticalAlign: 'middle' }}>
              <FaInfoCircle />
            </span>
          </Tooltip>
        </Box>

        <AnimatePresence mode="wait">
          <motion.div
            key={isLoading ? 'loading' : 'loaded'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Grid templateColumns={`repeat(${userGridColumns}, 1fr)`} gap={6} mt={4}>
              {isLoading ? (
                // Loading skeleton for Outlook integration
                <Box
                  bg="#F5F5F5"
                  p={8}
                  minHeight="350px"
                  borderRadius="8px"
                  border="1px"
                  borderColor="gray.200"
                >
                  <VStack spacing={4} align="center">
                    <Skeleton height="100px" width="100px" />
                    <SkeletonText mt={4} noOfLines={1} spacing='4' skeletonHeight='4' width="60%" />
                    <SkeletonText noOfLines={2} spacing='4' skeletonHeight='2' width="80%" />
                    <Skeleton height="50px" width="150px" mt={4} />
                  </VStack>
                </Box>
              ) : (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.4 }}
                >
                  <ActionBox 
                    image={<Image src="/icons/Microsoft_Office_Outlook.svg" alt="Outlook" boxSize="100px" objectFit="contain"/>}
                    description="Outlook"
                    description2={<Text mt={1}>Use emails from Outlook as an information source for your clients.</Text>}
                    buttonText={isMicrosoftConnected ? "Connected" : "Connect"}
                    buttonAction={connectToOutlook}
                  />
                </motion.div>
              )}
            </Grid>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </Box>
  );
};

const ActionBox = ({ 
  image, 
  description, 
  description2, 
  buttonText, 
  buttonAction, 
  isDisabled = false,
  disabledTooltip
}) => {
  return (
    <Box 
      bg="#F5F5F5" 
      pt={8} 
      pl={8} 
      pr={8} 
      pb={6}
      w="100%" 
      minHeight="350px" 
      position="relative" 
      borderRadius="8px"
      border="1px"
      borderColor="gray.200"
      boxShadow="sm"
      transition="box-shadow 0.3s ease"
      _hover={{ boxShadow: 'md' }}
      display="flex" 
      flexDirection="column" 
      justifyContent="space-between"
      opacity={isDisabled && buttonText !== "Connected" ? 0.7 : 1}
    >
      <VStack textAlign="center" spacing={4}>
        {image}
        <Text mt={4} fontSize={'18px'}>{description}</Text>
        {description2}
      </VStack>
      <Tooltip 
        label={isDisabled ? disabledTooltip : ""} 
        isDisabled={!isDisabled || buttonText === "Connected"}
      >
        <Button 
          mt={5}
          onClick={buttonAction} 
          bg="#A3BBD0" 
          w="150px" 
          h="50px" 
          alignSelf="center"
          isDisabled={isDisabled}
          _disabled={{
            bg: buttonText === "Connected" ? "#A3BBD0" : "gray.300",
            cursor: "not-allowed"
          }}
        >
          <Text fontWeight={400}>{buttonText}</Text>
        </Button>
      </Tooltip>
    </Box>
  );
};

export default Integrations;