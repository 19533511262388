// EmailTemplateEditor.js
import React from 'react';
import { VStack, Text, Box, FormControl, FormLabel, Input, Button, FormHelperText } from '@chakra-ui/react';
import ReactQuill from 'react-quill';

const EmailTemplateEditor = ({
  useEmailTemplate,
  emailTemplate,
  handleTemplateChange,
  quillRef,
  signatureImage,
  handleSignatureUpload,
  fileInputRef,
  defaultTemplate
}) => {
  if (!useEmailTemplate) return null;

  return (
    <VStack spacing={4} w="100%" mt={4} align="start">
      <Text fontWeight="bold">Email Template</Text>
      <Text fontSize="sm" color="gray.600">
        Available variables: clientName, todaysDate, body, signatureImage, advisorName
      </Text>
      <Box w="100%" h="400px" className="preserve-whitespace">
        <ReactQuill
          ref={quillRef}
          value={emailTemplate}
          onChange={handleTemplateChange}
          style={{ height: '350px' }}
          modules={{
            toolbar: [
              [{ 'header': [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['link', 'image'],
              ['clean']
            ],
            clipboard: {
              matchVisual: false
            },
            keyboard: {
              bindings: {
                tab: false,
                'indent-0': false
              }
            }
          }}
          formats={[
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet',
            'link', 'image'
          ]}
        />
      </Box>

      <FormControl>
        <FormLabel>Signature Image</FormLabel>
        <Input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleSignatureUpload}
          display="none"
        />
        <Button onClick={() => fileInputRef.current.click()}>
          Upload Signature
        </Button>
        <FormHelperText>
          Image must be at least 2550 pixels wide
        </FormHelperText>
      </FormControl>

      {signatureImage && (
        <Box mt={2}>
          <img src={signatureImage} alt="Signature" style={{ maxWidth: '300px' }} />
        </Box>
      )}
    </VStack>
  );
};

export default EmailTemplateEditor;