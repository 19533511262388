import React from 'react';
import { HStack, Heading, Button, Tooltip } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const ClientHeader = ({ client, onOpenInCrm }) => {
  return (
    <HStack justify="space-between">
      <Heading color="#00417D" size="lg">
        {client?.firstName} {client?.lastName}
      </Heading>
      {client?.crmId && client?.crmSource && (
        <Tooltip label={`Open in ${client.crmSource}`}>
          <Button
            leftIcon={<ExternalLinkIcon />}
            onClick={() => onOpenInCrm(client.crmId, client.crmSource)}
            size="sm"
            variant="outline"
            colorScheme="blue"
          >
            Open in {client.crmSource}
          </Button>
        </Tooltip>
      )}
    </HStack>
  );
};

export default ClientHeader; 