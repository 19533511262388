import React, { useEffect, useState, useMemo, memo, useRef } from 'react';
import { 
  Box, Heading, Text, VStack, HStack, Flex, IconButton, Progress, Button, 
  Input, Textarea, InputGroup, InputLeftElement, useToast, Grid, OrderedList, ListItem, Popover, PopoverTrigger, PopoverContent, PopoverBody
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ArrowBackIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, ChevronDownIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { FaPlay, FaPause, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import { useOrganizationUsers } from '../../../contexts/OrganizationUsersContext';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';

// Define Colors
const colors = {
  primary: "#00417D",
  secondary: "#0053BA",
  accent: "blue.200",
  background: "#F5F5F5",
  text: {
    primary: "#333333",
    secondary: "#555555",
  },
};

// Helper Functions
const formatTime = (seconds) => {
  if (!isFinite(seconds) || isNaN(seconds)) return "00:00";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

// Helper function to parse timestamp string "hh:mm:ss" into total seconds
const parseTimestamp = (timestamp) => {
  const parts = timestamp.split(':').map(Number);
  if (parts.length !== 3 || parts.some(isNaN)) return 0;
  const [hours, minutes, seconds] = parts;
  return hours * 3600 + minutes * 60 + seconds;
};

const calculateSpeakerStats = (transcription) => {
  const speakerStats = {};

  try {
    transcription.forEach(entry => {
      const { speaker, start, end, words } = entry;
      // console.log(`Processing entry for speaker: ${speaker}, start: ${start}, end: ${end}, words: ${words.length}`);

      const duration = end - start;
      // console.log(`Calculated duration for speaker ${speaker}: ${duration}`);

      if (!speakerStats[speaker]) {
        speakerStats[speaker] = { totalDuration: 0, totalWords: 0 };
        // console.log(`Initialized stats for speaker ${speaker}`);
      }

      speakerStats[speaker].totalDuration += duration;
      speakerStats[speaker].totalWords += words.length; // Each word is counted
      // console.log(`Updated stats for speaker ${speaker}: totalDuration = ${speakerStats[speaker].totalDuration}, totalWords = ${speakerStats[speaker].totalWords}`);
    });

    const overallTotalDuration = Object.values(speakerStats).reduce((acc, { totalDuration }) => acc + totalDuration, 0);
    // console.log(`Overall total duration: ${overallTotalDuration}`);

    for (const speaker in speakerStats) {
      const { totalDuration, totalWords } = speakerStats[speaker];
      speakerStats[speaker].percentageTime = overallTotalDuration > 0 ? Math.round((totalDuration / overallTotalDuration) * 100) : 0;
      speakerStats[speaker].wpm = totalDuration > 0 ? Math.round((totalWords / totalDuration) * 60) : 0;
      // console.log(`Final stats for speaker ${speaker}: percentageTime = ${speakerStats[speaker].percentageTime}, wpm = ${speakerStats[speaker].wpm}`);
    }
  } catch (error) {
    console.error('Error calculating speaker stats:', error);
    // Optionally, you can return an empty object or a default value
    return {};
  }

  return speakerStats;
};

const getTranscriptionDuration = (transcription) => {
  if (!transcription || transcription.length === 0) return 0;
  return Math.max(...transcription.map(entry => entry.end));
};

// The original UTC time is stored in the title between || and ||.
// This function extracts the time and formats it in the user's local timezone, to avoid timezone confusion between the server and client.
const formatTitleWithDate = (title) => {
  if (!title) return '';
  
  // Check if title contains an ISO timestamp between ||
  const matches = title.match(/\|\|(.*?)\|\|/);
  if (!matches) return title;

  // Extract the parts
  const [fullMatch, isoTime] = matches;
  const baseTitle = title.replace(fullMatch, '').trim();
  
  // Format the date in user's local timezone
  const date = new Date(isoTime);
  const formattedDate = date.toLocaleString(undefined, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  return `${baseTitle} on ${formattedDate}`;
};

const Correspondence = () => {
  const { id } = useParams();
  const [callData, setCallData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupedTranscription, setGroupedTranscription] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [isEditingSummary, setIsEditingSummary] = useState(false); 
  const [newSummary, setNewSummary] = useState(''); 
  const [newTopics, setNewTopics] = useState([]); 
  const [duration, setDuration] = useState(0); 
  const [transcriptionDuration, setTranscriptionDuration] = useState(0);
  const [isEditingTranscription, setIsEditingTranscription] = useState(false); 
  const [editedTranscription, setEditedTranscription] = useState([]); 
  const [activeSpeakerEdit, setActiveSpeakerEdit] = useState(null);
  const { users, loading: usersLoading } = useOrganizationUsers();
  const { clients, loading: clientsLoading } = useOrganizationClients();
  const serverUrl = process.env.REACT_APP_API_URL;
  const audioRef = React.useRef(null);
  const toast = useToast();
  const callDataRef = useRef();
  const [speakerSearchTerm, setSpeakerSearchTerm] = useState('');

  // Keep the ref updated
  useEffect(() => {
    callDataRef.current = callData;
  }, [callData]);

  // Helper function to format person's name
  const formatPersonName = (person) => {
    if (!person) return '';
    return `${person.firstName || ''} ${person.lastName || ''}`.trim();
  };

  const handleSpeakerUpdate = async (newSpeaker, currentSpeaker, groupIndex = null, applyToAll = false) => {
    try {
      const currentCallData = callDataRef.current;
      
      console.log('handleSpeakerUpdate called with:', {
        newSpeaker,
        currentSpeaker,
        groupIndex,
        applyToAll,
        callData: currentCallData
      });

      if (!currentCallData?.call || !Array.isArray(currentCallData.call.transcription)) {
        console.error('Call data not properly structured:', currentCallData);
        return;
      }

      let updatedTranscription;
      if (applyToAll) {
        updatedTranscription = currentCallData.call.transcription.map(entry => 
          entry.speaker === currentSpeaker ? { ...entry, speaker: newSpeaker.name } : entry
        );
      } else {
        updatedTranscription = currentCallData.call.transcription.map((entry, index) => {
          if (index === groupIndex && entry.speaker === currentSpeaker) {
            return { ...entry, speaker: newSpeaker.name };
          }
          return entry;
        });
      }

      // console.log("Updated Transcription: ", JSON.stringify(updatedTranscription, null, 2));

      const response = await axios.put(
        `${serverUrl}/update-transcript/${currentCallData.call.id}`,
        { 
          transcript: updatedTranscription,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );

      // Update the state after successful speaker update
      if (response.data?.call?.transcription) {
        setCallData(prev => ({
          ...prev,
          call: {
            ...prev.call,
            transcription: response.data.call.transcription,
          },
        }));
        
        setGroupedTranscription(groupTranscription(response.data.call.transcription));
        setActiveSpeakerEdit(null);
  
        toast({
          title: 'Speaker Updated',
          description: `Speaker name has been updated to ${newSpeaker.name}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      console.error('Error in handleSpeakerUpdate:', error);
      toast({
        title: 'Update Failed',
        description: 'Failed to update speaker name',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Replace the existing SpeakerSelectionContent component with this updated version
  const SpeakerSelectionContent = memo(({ person, speaker, groupIndex, handleSpeakerUpdate, setActiveSpeakerEdit }) => (
    <Box
      key={person.id}
      position="relative"
      transition="all 0.2s"
      borderRadius="md"
      _hover={{
        bg: 'gray.50',
        transform: 'translateX(2px)'
      }}
    >
      <Flex
        p={3}
        align="center"
        justify="space-between"
        cursor="pointer"
        onClick={() => {
          handleSpeakerUpdate(person, speaker, groupIndex, false);
          setActiveSpeakerEdit(null);
        }}
      >
        <HStack spacing={3}>
          <Box
            bg={person.type === 'client' ? 'blue.100' : 'green.100'}
            p={2}
            borderRadius="full"
            color={person.type === 'client' ? 'blue.700' : 'green.700'}
          >
            {person.type === 'client' ? '👤' : '👔'}
          </Box>
          <VStack align="start" spacing={0}>
            <Text fontWeight="medium">{person.name}</Text>
            <Text fontSize="xs" color="gray.500" textTransform="capitalize">
              {person.type}
            </Text>
          </VStack>
        </HStack>
        <HStack spacing={2}>
          <Button
            size="sm"
            variant="ghost"
            colorScheme={person.type === 'client' ? 'blue' : 'green'}
            onClick={(e) => {
              e.stopPropagation();
              handleSpeakerUpdate(person, speaker, null, true);
              setActiveSpeakerEdit(null);
            }}
          >
           Replace All
          </Button>
        </HStack>
      </Flex>
    </Box>
  ));

  // Memoized lists and content
  const availableSpeakers = useMemo(() => {
    const formattedUsers = users.map(user => ({
      id: user.id,
      name: formatPersonName(user),
      type: 'user'
    }));

    const formattedClients = clients.map(client => ({
      id: client.id,
      name: formatPersonName(client),
      type: 'client'
    }));

    return [...formattedUsers, ...formattedClients]
    .filter(person => person.name)
    .sort((a, b) => a.name.localeCompare(b.name));
  }, [users, clients]);

  // Update the speakerSelectionContent function
  const speakerSelectionContent = useMemo(() => (speaker, groupIndex) => {
    const filteredSpeakers = availableSpeakers.filter(person =>
      person.name.toLowerCase().includes(speakerSearchTerm.toLowerCase())
    );

    return (
      <VStack align="stretch" width="100%" spacing={2} p={3}>
        <InputGroup size="md">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Search speakers..."
            value={speakerSearchTerm}
            onChange={(e) => setSpeakerSearchTerm(e.target.value)}
            bg="white"
            borderWidth="2px"
            _focus={{
              borderColor: colors.secondary,
              boxShadow: 'none'
            }}
          />
        </InputGroup>
        
        {filteredSpeakers.length > 0 ? (
          <VStack 
            align="stretch" 
            spacing={1} 
            maxH="300px" 
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: colors.primary,
                borderRadius: '24px',
              },
            }}
          >
            {filteredSpeakers.map((person) => (
              <SpeakerSelectionContent
                key={person.id}
                person={person}
                speaker={speaker}
                groupIndex={groupIndex}
                handleSpeakerUpdate={handleSpeakerUpdate}
                setActiveSpeakerEdit={setActiveSpeakerEdit}
              />
            ))}
          </VStack>
        ) : (
          <Box textAlign="center" py={4}>
            <Text color="gray.500">No speakers found</Text>
          </Box>
        )}
      </VStack>
    );
  }, [availableSpeakers, speakerSearchTerm, colors]);

  // Update the renderSpeakerName function
  const renderSpeakerName = (speaker, groupIndex) => {
    const speakerKey = `${speaker}-${groupIndex}`;
    
    return (
      <Popover
        isOpen={activeSpeakerEdit === speakerKey}
        onClose={() => {
          setActiveSpeakerEdit(null);
          setSpeakerSearchTerm('');
        }}
        placement="right-start"
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Button
            variant="ghost"
            size="sm"
            display="flex"
            alignItems="center"
            fontWeight="bold"
            color={colors.primary}
            onClick={() => setActiveSpeakerEdit(activeSpeakerEdit === speakerKey ? null : speakerKey)}
            leftIcon={<FaEdit size="12px" />}
            rightIcon={activeSpeakerEdit === speakerKey ? <ChevronUpIcon /> : <ChevronDownIcon />}
            _hover={{
              bg: 'blue.50'
            }}
          >
            {speaker}
          </Button>
        </PopoverTrigger>
        <PopoverContent width="350px" border="none" boxShadow="lg" borderRadius="xl">
          <PopoverBody p={0}>
            {speakerSelectionContent(speaker, groupIndex)}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };
  
  useEffect(() => {
    const fetchTranscript = async () => {
      try {
        const response = await axios.get(serverUrl + `/transcripts?id=${id}`, { headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }, withCredentials: true });
        // console.log("Received Response: ", response.data);
        setCallData(response.data);
        setNewTitle(formatTitleWithDate(response.data.call.title)); 
        setNewSummary(response.data.call?.summary ?? ''); 
        setNewTopics(response.data.call?.topics?.topics ?? []); 
        const grouped = groupTranscription(response.data.call.transcription);
        setGroupedTranscription(grouped);
        
        setTranscriptionDuration(getTranscriptionDuration(response.data.call.transcription));
      } catch (error) {
        console.error('Error fetching transcript:', error);
        toast({
          title: 'Error',
          description:
            error.response?.data?.error || 'Failed to fetch transcript.',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    };
    fetchTranscript();
  }, [id, serverUrl, toast]);

  const groupTranscription = (transcription) => {
    // Since the new format already groups by speaker, we can return it as is
    return transcription.map(entry => ({
      speaker: entry.speaker,
      entries: [entry]
    }));
  };

  const handlePlayPause = () => {
    if (!audioRef.current) return;

    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    if (!audioRef.current) return; 

    const currentTime = audioRef.current.currentTime;
    const audioDuration = audioRef.current.duration;
    
    // Use transcription duration as fallback
    const effectiveDuration = isFinite(audioDuration) && audioDuration > 0 
      ? audioDuration 
      : transcriptionDuration;
    
    setCurrentTime(currentTime);
    
    if (effectiveDuration > 0) {
      const progress = (currentTime / effectiveDuration) * 100;
      setProgress(progress);
      setDuration(effectiveDuration);
    }
  };

  const handleSkip = (seconds) => {
    if (!audioRef.current) return; 
    audioRef.current.currentTime += seconds;
  };

  const jumpToTimestamp = (timestamp) => {
    if (!audioRef.current) return; 
    audioRef.current.currentTime = timestamp;
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const filteredTranscription = groupedTranscription.filter(group =>
    group.entries.some(entry =>
      entry.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.speaker.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (!callData) {
    return <Box>Loading...</Box>;
  }

  const handleSeekClick = (e) => {
    if (!audioRef.current) return; // Safeguard
    const progressBar = e.target;
    const rect = progressBar.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    
    // Use transcription duration as fallback
    const effectiveDuration = isFinite(audioRef.current.duration) && audioRef.current.duration > 0 
      ? audioRef.current.duration 
      : transcriptionDuration;
    
    const seekTime = (clickX / progressBar.clientWidth) * effectiveDuration;
    audioRef.current.currentTime = seekTime;
    setProgress((seekTime / effectiveDuration) * 100);
  };

  const getHighlightedAndLineBreakedText = (entry, groupIndex, entryIndex) => {
    // Only split on periods followed by capital letters
    const sentences = entry.text
      .split(/(?<=\.)\s+(?=[A-Z])/)
      .filter(sentence => sentence.trim());
    
    const formattedText = sentences.map((sentence, index) => (
      <React.Fragment key={index}>
        {index > 0 && index % 4 === 0 && <><br /><br /></>}
        {sentence.trim()}{' '}
      </React.Fragment>
    ));
    
    // Determine if the entire utterance should be highlighted
    const isHighlighted = currentTime >= entry.start && currentTime <= entry.end;
    const uniqueKey = `${groupIndex}-${entryIndex}-${entry.start}`;
    // console.log("Unique Key: ", uniqueKey);
  
    if (isHighlighted) {
      console.log(`Highlighted Utterance: "${entry.text}", Start: ${entry.start}, End: ${entry.end}, Current Time: ${currentTime}`);
    }
  
    return (
      <Text
        as="span"
        key={uniqueKey}
        bg={isHighlighted ? colors.accent : 'transparent'}
      >
        {formattedText}
      </Text>
    );
  };

  // Event Handlers for Title Editing
  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
    setNewTitle(callData.call.title);
  };

  const handleSaveTitle = async () => {
    if (newTitle.trim() === '') {
      toast({
        title: 'Validation Error',
        description: 'Title cannot be empty.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${serverUrl}/update-title/${callData.call.id}`,
        { title: newTitle },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`,
          },
        }
      );
      setCallData((prev) => ({
        ...prev,
        call: {
          ...prev.call,
          title: response.data.call.title,
        },
      }));
      setIsEditingTitle(false);
      toast({
        title: 'Title Updated',
        description: 'The call title has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating title:', error);
      toast({
        title: 'Update Failed',
        description: 'There was an error updating the title.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Event Handlers for Summary Editing
  const handleEditSummary = () => {
    setIsEditingSummary(true);
  };

  const handleCancelEditSummary = () => {
    setIsEditingSummary(false);
    setNewSummary(callData.call.summary ?? '');
  };

  const handleSaveSummary = async () => {
    if (newSummary.trim() === '') {
      toast({
        title: "Validation Error",
        description: "Summary cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const formattedSummary = newSummary.trim();

      const response = await axios.put(
        `${serverUrl}/update-summary/${callData.call.id}`,
        { summary: formattedSummary },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );

      // Split the summary into list items by newline for display
      const summaryItems = formattedSummary.split('\n').filter(item => item.trim() !== '');

      setCallData((prev) => ({
        ...prev,
        call: {
          ...prev.call,
          summary: response.data.summary,
        },
      }));
      setNewSummary(summaryItems.join('\n')); // Ensure newSummary is newline-separated
      setIsEditingSummary(false);
      toast({
        title: "Summary Updated",
        description: "The summary has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating summary:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the summary.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Event Handlers for Transcription Editing
  const handleEditTranscription = () => {
    setEditedTranscription(groupedTranscription.map(group => ({
      ...group,
      editedText: group.entries.map(entry => entry.text).join(' ')
    })));
    setIsEditingTranscription(true);
  };

  const handleCancelEditTranscription = () => {
    setIsEditingTranscription(false);
    setEditedTranscription([]);
  };

  const handleTranscriptionChange = (index, newText) => {
    const updatedTranscription = [...editedTranscription];
    updatedTranscription[index].editedText = newText;
    setEditedTranscription(updatedTranscription);
  };

  const handleSaveTranscription = async () => {
    try {
      // Prepare the updated transcription array
      const updatedTranscriptionArray = editedTranscription.flatMap((group, groupIndex) => {
        // Split the edited text into words
        const editedWords = group.editedText.split(/\s+/).filter(text => text);
  
        // Get the original words for this group to preserve timings
        const originalGroup = callData.call.transcription[groupIndex];
        const originalWords = originalGroup.words || [];
  
        // Map edited words to original word timestamps
        const words = editedWords.map((text, wordIndex) => {
          const originalWord = originalWords[wordIndex];
          if (originalWord) {
            return {
              text,
              speaker: group.speaker,
              start: originalWord.start,
              end: originalWord.end,
              confidence: originalWord.confidence
            };
          } else {
            // Fallback: Assign approximate timings if original word is unavailable
            const lastEnd = originalWords[wordIndex - 1]?.end || group.start;
            return {
              text,
              speaker: group.speaker,
              start: lastEnd,
              end: lastEnd, // Assign a default duration of 0 seconds
              confidence: 1.0 // Assuming full confidence for added words
            };
          }
        });
  
        return [{
          speaker: group.speaker,
          start: originalGroup.start,
          end: originalGroup.end,
          text: editedWords.join(' '),
          words: words
        }];
      });

      // Calculate and log the request payload size
      const requestPayload = { transcript: updatedTranscriptionArray };
      const payloadSize = new Blob([JSON.stringify(requestPayload)]).size;
      console.log(`Request payload size: ${(payloadSize / 1024 / 1024).toFixed(2)} MB`);

      // If payload is too large, show warning
      if (payloadSize > 10 * 1024 * 1024) { // 10MB threshold
        toast({
          title: 'Warning: Large Payload',
          description: `Request size (${(payloadSize / 1024 / 1024).toFixed(2)} MB) may be too large for the server.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }

      // Proceed with the request
      const response = await axios.put(
        `${serverUrl}/update-transcript/${callData.call.id}`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
          maxContentLength: Infinity, 
          maxBodyLength: Infinity, 
        }
      );

      // Update the callData and groupedTranscription with the new transcription
      setCallData(prev => ({
        ...prev,
        call: {
          ...prev.call,
          transcription: response.data.call.transcription,
        },
      }));
      setGroupedTranscription(groupTranscription(response.data.call.transcription));
      setIsEditingTranscription(false);
      setEditedTranscription([]);
      toast({
        title: 'Transcription Updated',
        description: 'The transcription has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating transcription:', error);
      // Add more detailed error logging
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      
      toast({
        title: 'Update Failed',
        description: error.response?.data?.message || 'There was an error updating the transcription. The payload might be too large.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const speakerStats = calculateSpeakerStats(callData.call.transcription);

  return (
    <Box bg="white" h="100%" p={8} pb={24} overflowY="auto">
      {/* <Container maxW="container.lg"> */}
        <IconButton
          aria-label="Go back"
          icon={<ArrowBackIcon />}
          onClick={() => window.history.back()}
          mb={4}
          variant="ghost"
          color="gray.600"
          _hover={{ bg: "gray.100" }}
        />
        <VStack align="stretch" spacing={6}>

          {/* Editable Title Section */}
          <Box position="relative" role="group">
            {isEditingTitle ? (
              <VStack align="start" spacing={2}>
                <Input
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="Enter new title"
                  size="lg"
                  borderColor={colors.primary}
                  _focus={{ borderColor: colors.secondary, boxShadow: 'outline' }}
                />
                <HStack spacing={2}>
                  <Button size="sm" colorScheme="green" onClick={handleSaveTitle} leftIcon={<FaSave />}>
                    Save
                  </Button>
                  <Button size="sm" colorScheme="red" onClick={handleCancelEditTitle} leftIcon={<FaTimes />}>
                    Cancel
                  </Button>
                </HStack>
              </VStack>
            ) : (
              <HStack align="center" spacing={2}>
                <Heading color={colors.primary} fontSize={{ base: '28px', md: '36px' }}>
                  {formatTitleWithDate(callData.call.title)}
                </Heading>
                <IconButton
                  aria-label="Edit Title"
                  icon={<FaEdit />}
                  size="sm"
                  onClick={handleEditTitle}
                  variant="ghost"
                  colorScheme="blue"
                  _hover={{ bg: "blue.50", transform: "scale(1.05)" }}
                  transition="background-color 0.3s ease, transform 0.2s ease"
                />
              </HStack>
            )}
          </Box>

          {/* Editable Summary Section */}
          <Box position="relative" role="group">
            <HStack align="center" spacing={2}>
              <Heading size="md" color={colors.primary}>
                Summary
              </Heading>
              {!isEditingSummary ? (
                <IconButton
                  aria-label="Edit Summary"
                  icon={<FaEdit />}
                  size="sm"
                  onClick={handleEditSummary}
                  variant="ghost"
                  colorScheme="blue"
                  _hover={{ bg: "blue.50", transform: "scale(1.05)" }}
                  transition="background-color 0.3s ease, transform 0.2s ease"
                />
              ) : (
                <>
                  <IconButton
                    aria-label="Save Summary"
                    icon={<FaSave />}
                    size="sm"
                    onClick={handleSaveSummary}
                    colorScheme="green"
                    variant="ghost"
                  />
                  <IconButton
                    aria-label="Cancel Summary Edit"
                    icon={<FaTimes />}
                    size="sm"
                    onClick={handleCancelEditSummary}
                    colorScheme="red"
                    variant="ghost"
                  />
                </>
              )}
            </HStack>
            <VStack align="start" spacing={2}>
              {/* Render Summary as Ordered List When Not Editing */}
              {!isEditingSummary && (
                <OrderedList
                  fontSize={{ base: '16px', md: '18px' }}
                  color={colors.text.secondary}
                  spacing={3}
                  pl={5}
                >
                {newSummary
                  .split('\n')
                  .filter(item => item.trim() !== '') // Filter out empty lines
                  .map((item, index) => (
                    <ListItem key={index}>{item}</ListItem>
                  ))}
                </OrderedList>
              )}
              {/* Render Textarea for Editing Summary */}
              {isEditingSummary && (
                <VStack align="stretch" spacing={2} width="100%">
                  <Textarea
                    value={newSummary}
                    onChange={(e) => setNewSummary(e.target.value)}
                    placeholder="Enter new summary, each point on a new line"
                    size="md"
                    borderColor={colors.primary}
                    _focus={{ borderColor: colors.secondary, boxShadow: 'outline' }}
                    resize="vertical"
                    fontSize={{ base: '16px', md: '18px' }}
                    color={colors.text.primary}
                    lineHeight="1.5"
                    height={{ base: "120px", md: "150px" }}
                  />
                  <Text fontSize="sm" color="gray.500">
                    Enter each summary point on a separate line.
                  </Text>
                </VStack>
              )}
            </VStack>
          </Box>

          {/* Search Bar */}
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search transcript"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              borderColor={colors.primary}
              _focus={{ borderColor: colors.secondary, boxShadow: 'outline' }}
            />
          </InputGroup>
          
          {/* Call Topics */}
          <Box>
            <Heading size="md" mb={2} color={colors.primary}>
              Topics
            </Heading>
            {newTopics.length > 0 ? (
              <VStack align="start" spacing={1}>
                {newTopics.map((topic, index) => (
                  <HStack key={index}>
                    <Text fontSize={{ base: '16px', md: '18px' }} color={colors.text.secondary}>
                      {topic.topic}
                    </Text>
                    <Button
                      variant="link"
                      color="blue.500"
                      onClick={() =>
                        jumpToTimestamp(parseTimestamp(topic.timestamp))
                      }
                      size="sm"
                      leftIcon={<FaPlay />}
                    >
                      {formatTime(parseTimestamp(topic.timestamp))}
                    </Button>
                  </HStack>
                ))}
              </VStack>
            ) : (
              <Text fontSize={{ base: '16px', md: '18px' }} color={colors.text.secondary}>
                No topics were created for this transcript.
              </Text>
            )}
          </Box>

          {/* Speaker Statistics */}
          <Box>
            <Heading size="md" mb={2} color={colors.primary}>
              Speaker Statistics
            </Heading>
            <VStack align="start" spacing={1}>
              {Object.entries(speakerStats).map(([speaker, stats]) => (
                <Text key={speaker} fontSize={{ base: '16px', md: '18px' }} color={colors.text.secondary}>
                  {speaker}: {stats.percentageTime}% of time spoken, {stats.wpm} WPM
                </Text>
              ))}
            </VStack>
          </Box>

          {/* Editable Transcription Section */}
          <Box position="relative" role="group">
            <HStack align="center" spacing={2}>
              <Heading size="md" color={colors.primary}>
                Transcription
              </Heading>
              {!isEditingTranscription ? (
                <IconButton
                  aria-label="Edit Transcription"
                  icon={<FaEdit />}
                  size="sm"
                  onClick={handleEditTranscription}
                  variant="ghost"
                  colorScheme="blue"
                  _hover={{ bg: "blue.50", transform: "scale(1.05)" }}
                  transition="background-color 0.3s ease, transform 0.2s ease"
                />
              ) : (
                <>
                  <IconButton
                    aria-label="Save Transcription"
                    icon={<FaSave />}
                    size="sm"
                    onClick={handleSaveTranscription}
                    colorScheme="green"
                    variant="ghost"
                  />
                  <IconButton
                    aria-label="Cancel Transcription Edit"
                    icon={<FaTimes />}
                    size="sm"
                    onClick={handleCancelEditTranscription}
                    colorScheme="red"
                    variant="ghost"
                  />
                </>
              )}
            </HStack>
            {isEditingTranscription ? (
              <VStack align="stretch" spacing={4} mt={4}>
                {editedTranscription.map((group, index) => (
                  <Flex key={index} align="start" p={3} borderRadius="md" boxShadow="sm" bg={colors.background}>
                    <Text fontWeight="bold" color={colors.primary} mr={2}>
                      {group.speaker}:
                    </Text>
                    <Textarea
                      value={group.editedText}
                      onChange={(e) => handleTranscriptionChange(index, e.target.value)}
                      placeholder={`Edit transcription for ${group.speaker}`}
                      size="md"
                      borderColor={colors.primary}
                      _focus={{ borderColor: colors.secondary, boxShadow: 'outline' }}
                      resize="vertical"
                      fontSize={{ base: '16px', md: '18px' }}
                      color={colors.text.primary}
                      lineHeight="1.5"
                      minHeight="150px"
                      backgroundColor="white"
                      paddingLeft="0"
                      paddingRight="0"
                      width="100%"
                    />
                    <Text fontSize="sm" ml={2} mt={1} color="blue.500">
                      {formatTime(group.entries[0].start)}
                    </Text>
                  </Flex>
                ))}
              </VStack>
            ) : (
              <Box>
                {filteredTranscription.map((group, groupIndex) => (
                  // console.log(`Rendering groupIndex: ${groupIndex}`), 
                  // console.log(`Group: ${JSON.stringify(group, null, 2)}`),
                  <Flex
                    key={groupIndex}
                    mt={2}
                    align="start"
                    p={3}
                    borderRadius="md"
                    boxShadow="sm"
                    transition="background-color 0.3s ease, box-shadow 0.3s ease"
                    _hover={{ bg: colors.accent, boxShadow: 'md' }}
                  >
                    <Box flex="1">
                      <Flex gap={2}>
                        <Box>
                          {renderSpeakerName(group.speaker, groupIndex)}
                        </Box>
                        <Box flex="1" mt={2}>
                          {group.entries.map((entry, entryIndex) => getHighlightedAndLineBreakedText(entry, groupIndex, entryIndex))}
                        </Box>
                      </Flex>
                    </Box>
                    <Button 
                      size="xs" 
                      ml={2} 
                      mt={2}
                      variant="link"
                      color="blue.500"
                      onClick={() => jumpToTimestamp(group.entries[0].start)}
                    >
                      {formatTime(group.entries[0].start)}
                    </Button>
                  </Flex>
                ))}
              </Box>
            )}
          </Box>
        </VStack>
      {/* </Container> */}

      {/* Audio Player */}
      <Box
        position="fixed"
        bottom={0}
        left={{ base: 0, md: 242 }}  
        right={{ base: 0, md: 8 }}
        bg={colors.background}
        color={colors.primary}
        p={4}
        boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
        zIndex={1000}
      >
        <Flex align="center" justify="space-between">
          <HStack spacing={4}>
            <IconButton
              icon={isPlaying ? <FaPause /> : <FaPlay />}
              onClick={handlePlayPause}
              aria-label={isPlaying ? "Pause" : "Play"}
              colorScheme="blue"
              variant="ghost"
              size="lg"
            />
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => handleSkip(-10)}
              aria-label="Rewind 10 seconds"
              variant="ghost"
            />
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => handleSkip(10)}
              aria-label="Forward 10 seconds"
              variant="ghost"
            />
            <IconButton
              icon={<RepeatIcon />}
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.currentTime = 0;
                  audioRef.current.play();
                }
              }}
              aria-label="Restart"
              variant="ghost"
            />
          </HStack>
          <Progress
            value={progress}
            flex={1}
            size="sm"
            colorScheme="blue"
            onClick={handleSeekClick}
            sx={{ cursor: 'pointer', mx: 4 }}
            borderRadius="md"
          />
          <Text fontSize="sm">
            {formatTime(currentTime)} / {formatTime(isFinite(duration) ? duration : transcriptionDuration)}
          </Text>
        </Flex>
        <audio
          ref={audioRef}
          src={callData.audioUrl}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={() => {
            if (audioRef.current) {
              setDuration(audioRef.current.duration);
            }
          }}
          onEnded={() => setIsPlaying(false)}
          style={{ display: 'none' }}
        />
      </Box>
    </Box>
  );
};

export default Correspondence;